import React, { useCallback, useState } from 'react'
import classNames from 'classnames'
import { getCookie } from 'cookies-next'
import { When } from 'react-if'
import { CheckIcon } from '@/atoms/Icons/CheckIcon'
import { CopyIcon } from '@/atoms/Icons/CopyIcon'
import { EnvelopeIcon } from '@/atoms/Icons/EnvelopeIcon'
import { FacebookIcon } from '@/atoms/Icons/FacebookIcon'
import { LinkIcon } from '@/atoms/Icons/LinkIcon'
import { RedditIcon } from '@/atoms/Icons/RedditIcon'
import { TelegramIcon } from '@/atoms/Icons/TelegramIcon'
import { TwitterXIcon } from '@/atoms/Icons/TwitterXIcon'
import { ParagraphMD } from '@/atoms/Text'
import { OneColorIconProps } from '@/atoms/utils'
import { SEGMENT_USER_ID_COOKIE } from '@/constants/cookies'
import { NamedColor } from '@/constants/types'
import { useToast } from '@/molecules/Toast'
import { useTranslate } from '@/utils/translate/translate-client'

interface SocialSharingProps {
  color?: NamedColor
  url?: string
  vertical?: boolean
  onShare?: (network: string) => void
  projectSlug?: string
}

interface SocialShare {
  name: string
  icon: React.FC<OneColorIconProps>
  href?: string
  onClick?: () => void
  brandColor: string
}

interface UseSocialSharingValues {
  links: SocialShare[]
  socials: Record<string, SocialShare>
}

export const createWebSharingUrl = (urlString: string, projectSlug: string | undefined): string => {
  const userId = getCookie(SEGMENT_USER_ID_COOKIE)
  const url = new URL(urlString)

  if (typeof userId === 'string') {
    url.searchParams.append('refuid', userId)
  }
  if (projectSlug) {
    url.searchParams.append('project', projectSlug)
  }
  return url.toString()
}

export const useSocialSharing = ({
  url,
  projectSlug,
}: {
  url: string | undefined
  projectSlug: string | undefined
}): UseSocialSharingValues | null => {
  const { t } = useTranslate('common')
  const linkCopied = t('linkCopied', 'Link copied.')

  const setUpUrl = (url: string | undefined, projectSlug: string | undefined): string => {
    if (url) {
      return createWebSharingUrl(url, projectSlug)
    }
    return ''
  }

  const toast = useToast()

  // link is an async branch url that we need to kick out early if we don't have it.
  if (!url) return null

  // intentionally set to run on every render. We had issues with missing cookie or race condition when coming back from login. This fixes it and processing is lite.
  const link = setUpUrl(url, projectSlug)

  const socials = {
    copyLink: {
      name: t('copyLink', 'Copy Link'),
      icon: LinkIcon,
      onClick: () => {
        navigator.clipboard.writeText(link)
        toast?.showToast(linkCopied, { icon: <CopyIcon size={24} color="gray-200" /> })
      },
      brandColor: '#000',
    },
    email: {
      name: t('email', 'Email'),
      icon: EnvelopeIcon,
      href: `mailto:?subject=Join%20me%20on%20Angel%20Studios&body=Come%20and%20see%20this%20from%20Angel%20Studios:%0D%0D${link}`,
      brandColor: '#000',
    },
    twitter: {
      name: 'Twitter',
      icon: TwitterXIcon,
      href: `https://twitter.com/share?url=${link}`,
      brandColor: '#1DA1F2',
    },
    facebook: {
      name: 'Facebook',
      icon: FacebookIcon,
      href: `https://www.facebook.com/sharer/sharer.php?u=${link}`,
      brandColor: '#1877F2',
    },
    reddit: {
      name: 'Reddit',
      icon: RedditIcon,
      href: `http://reddit.com/submit?url=${link}`,
      brandColor: '#FF4500',
    },
    telegram: {
      name: 'Telegram',
      icon: TelegramIcon,
      href: `https://telegram.me/share/url?url=${link}`,
      brandColor: '#27A7E7',
    },
  }
  return {
    socials,
    links: Object.values(socials),
  }
}

export const SocialSharing: React.FC<SocialSharingProps> = ({
  color = 'gray-700',
  url,
  vertical = false,
  onShare,
  projectSlug,
}) => {
  const [copied, setCopy] = useState<boolean>(false)
  const [copiedHref, setCopiedHref] = useState<string>('')
  const socialSharingLinks = useSocialSharing({ url, projectSlug })
  const { t } = useTranslate('common')

  const handleButtonClick = useCallback(() => {
    setCopy(true)
    setTimeout(() => {
      setCopy(false)
    }, 5000)
  }, [])

  const handleHrefButtonClick = useCallback((name: string) => {
    setCopiedHref(name)
    setTimeout(() => {
      setCopiedHref('')
    }, 5000)
  }, [])

  return (
    <div className="w-full">
      <div className="mx-auto max-w-sm">
        <div className={classNames('w-full flex-row justify-between hidden lg:flex xl:hidden gap-x-2')}>
          {socialSharingLinks?.links?.map(({ name, onClick, icon: Icon }) => {
            if (onClick) {
              return (
                <div className="mb-4 w-full" key={name}>
                  <button
                    aria-label={name}
                    disabled={copied}
                    className={classNames(
                      'min-w-[96px] flex flex-row focus-visible:outline-none focus-visible:ring-0 focus-visible:right-offset-0 focus-visible:ring-transparent rounded-full w-full h-10 p-4 justify-center items-center',
                      copied ? 'bg-core-gray-950' : 'bg-gray-200 hover:bg-gray-400',
                    )}
                    onClick={() => {
                      onShare?.('Copy to Clipboard')
                      handleButtonClick()
                      onClick()
                    }}
                  >
                    {copied ? <CheckIcon color={'white'} size={16} /> : <Icon color={'gray-950'} size={16} />}
                    <p
                      className={classNames(
                        'text-sm font-normal capitalize',
                        copied ? 'text-white' : 'text-gray-950 ml-1',
                      )}
                    >
                      {copied ? t('copied', 'Copied') : t('copyV1', 'Copy')}
                    </p>
                    <When condition={vertical}>
                      <ParagraphMD className="my-auto cursor-pointer" color={color}>
                        {name}
                      </ParagraphMD>
                    </When>
                  </button>
                </div>
              )
            }
          })}
        </div>
        <div className={classNames('w-full flex flex-row justify-between gap-x-3')}>
          {socialSharingLinks?.links?.map(({ name, href, onClick, icon: Icon }) => {
            if (onClick) {
              return (
                <div className="flex w-fit lg:hidden xl:flex" key={name}>
                  <button
                    aria-label={name}
                    disabled={copied}
                    className={classNames(
                      'min-w-[96px] flex flex-row focus-visible:outline-none focus-visible:ring-0 focus-visible:right-offset-0 focus-visible:ring-transparent rounded-full w-fit h-10 p-4 justify-center items-center',
                      copied ? 'bg-core-gray-950' : 'bg-gray-200 hover:bg-gray-400',
                    )}
                    onClick={() => {
                      onShare?.('Copy to Clipboard')
                      handleButtonClick()
                      onClick()
                    }}
                  >
                    {copied ? <CheckIcon color={'white'} size={16} /> : <Icon color={'gray-950'} size={16} />}
                    <p
                      className={classNames(
                        'text-sm font-normal capitalize',
                        copied ? 'text-white' : 'text-gray-950 ml-1',
                      )}
                    >
                      {copied ? t('copied', 'Copied') : t('copyV1', 'Copy')}
                    </p>
                    <When condition={vertical}>
                      <ParagraphMD className="my-auto cursor-pointer" color={color}>
                        {name}
                      </ParagraphMD>
                    </When>
                  </button>
                </div>
              )
            }
            if (href) {
              return (
                <a
                  aria-label={name}
                  key={name}
                  className={classNames(
                    'flex flex-row focus-visible:outline-none focus-visible:ring-0 focus-visible:right-offset-0 focus-visible:ring-transparent transition transition-speed-[2s] ease-in-out rounded-full w-10 h-10 justify-center items-center',
                    copiedHref === name
                      ? 'bg-core-gray-950 cursor-not-allowed pointer-events-none'
                      : 'bg-gray-200 hover:bg-gray-400 cursor-pointer',
                  )}
                  href={href}
                  rel="noreferrer"
                  target="_blank"
                  onClick={() => {
                    onShare?.(name)
                    handleHrefButtonClick(name)
                  }}
                >
                  {copiedHref === name ? (
                    <CheckIcon color={'white'} size={16} />
                  ) : (
                    <Icon color={'gray-950'} size={16} />
                  )}
                  <When condition={vertical}>
                    <ParagraphMD className="my-auto cursor-pointer" color={color}>
                      {name}
                    </ParagraphMD>
                  </When>
                </a>
              )
            }
          })}
        </div>
      </div>
    </div>
  )
}
